import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { css, withTheme } from "@delight-js/react";
import { useSpring, animated, config } from "@react-spring/web";

const Image = withTheme(
  ({ theme, image, alt = "", title, sizes, loading, ...props }) => {
    const [styles, springApi] = useSpring(() => ({
      translateX: "0%",
      scaleX: 0,
    }));
    const handleStart = (info) => {
      if (info.wasCached) {
        return;
      }
      springApi.start({
        translateX: "0%",
        scaleX: 1,
        config: config.molasses,
      });
    };
    const handleLoad = () => {
      springApi.start({
        translateX: "101%",
        scaleX: 1,
        config: {
          tension: 170,
          friction: 30,
          clamp: true,
        },
      });
    };
    return (
      <div
        css={css`
          position: relative;
          background: $color-imageBg;
          overflow: hidden;
        `(theme)}
        {...props}
      >
        <GatsbyImage
          image={image}
          alt={alt}
          title={title}
          onLoad={handleLoad}
          onStartLoad={handleStart}
          placeholder="none"
          sizes={sizes}
          loading={loading}
        />
        <animated.div
          css={css`
            position: absolute;
            transform-origin: left center;
            z-index: 3;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: $color-imageFg;
            transform: scaleX(0);
          `(theme)}
          style={{
            ...styles,
          }}
          aria-hidden="true"
        ></animated.div>
      </div>
    );
  }
);

export default Image;
