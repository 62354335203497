import * as React from "react";
import { css, withTheme } from "@delight-js/react";
import Link from "./Link";
import Row from "../components/layout/Row";
import Cell from "../components/layout/Cell";
import { H3, Overline } from "../components/texts";
import Icon from "../components/Icon";
import Image from "../components/Image";

const PreviewRow = withTheme(({ theme, overline, title, image, ...props }) => {
  const [hover, setHover] = React.useState(false);
  const isPlaceholder = !title && !image;
  const toggleHover = ({ type }) => setHover(type === "mouseover");
  return (
    <Row
      as={isPlaceholder ? "div" : Link}
      onMouseOver={toggleHover}
      onMouseOut={toggleHover}
      css={
        isPlaceholder
          ? css`
              color: $color-bg2;
              @media:xl {
                align-items: center;
              }
            `(theme)
          : css`
              color: $color-spot1;
              text-decoration: none;
              transition: color 0.35s;

              @media:xl {
                align-items: center;
                .gatsby-image-wrapper {
                  transition: transform 0.7s cubic-bezier(0.5, 1, 0.5, 1);
                }
              }

              &:hover {
                color: $color-spot2;

                @media:xl {
                  .gatsby-image-wrapper {
                    transform: scale3d(1.1, 1.1, 1);
                  }
                }
              }
            `(theme)
      }
      {...props}
    >
      <Cell
        span={{ default: 10 / 12, md: 8 / 12, xl: 5 / 12 }}
        offset={{ default: 1 / 12, md: 2 / 12, xl: 0 }}
        css={css`
          @media:xl {
            order: 2;
          }
        `(theme)}
      >
        <Overline
          as="p"
          css={
            isPlaceholder
              ? css`
                  width: 60%;
                  min-height: 0.8em;
                  background: $color-bg2;
                `
              : css`
                  color: currentColor;
                  a:hover & {
                    filter: brightness(0.9);
                  }
                `
          }
        >
          {overline}
        </Overline>
        <H3
          css={
            isPlaceholder
              ? css`
                  width: 75%;
                  min-height: 0.5em;
                  border: solid 0.5em $color-bg2;
                  border-width: 0.5em 0;
                  left: 0;
                  margin-top: 0.5em;
                `
              : css`
                  color: currentColor;
                `
          }
        >
          {title}
        </H3>
      </Cell>
      <Cell
        span={{ default: 10 / 12, md: 8 / 12, xl: 4 / 12 }}
        offset={{ default: 1 / 12, md: 2 / 12, xl: 1 / 12 }}
        css={css`
          @media:xl {
            order: 1;
          }
        `(theme)}
      >
        {!!image && (
          <Image
            image={image.childImageSharp.gatsbyImageData}
            sizes="(min-width: 1024px) 33vw,(min-width: 640px) 66vw, 83vw"
            alt={title}
          />
        )}
        {!!isPlaceholder && (
          <div
            css={css`
              width: 100%;
              height: 0;
              padding-bottom: 56.25%;
              background-color: $color-bg2;
            `(theme)}
          ></div>
        )}
      </Cell>
      <Cell
        span={{ default: 1 / 12 }}
        css={css`
          display: none;
          @media:xl {
            display: block;
            order: 3;
          }
        `(theme)}
      >
        <Icon
          type="arrowRight"
          css={css`
            display: block;
            max-width: 100%;
            height: auto;
            margin-left: auto;
          `(theme)}
          innerProps={{ hover }}
        />
      </Cell>
    </Row>
  );
});

export default PreviewRow;
