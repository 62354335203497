import * as React from "react";
import { css } from "@delight-js/react";

const baseStyle = {
  stroke: "currentColor",
  strokeWidth: "1",
  strokeLinecap: "round",
  fill: "none",
};

const hoverBorderStyle = {
  strokeWidth: "3",
};

const OutlineCircle = ({ id, strokeWidth, r = 38, filled }) => (
  <React.Fragment>
    <defs>
      <clipPath id={id}>
        <circle cx={r} cy={r} r={r}></circle>
      </clipPath>
    </defs>
    {filled && (
      <circle
        cx={r}
        cy={r}
        r={r}
        stroke="none"
        css={css`
          fill: rgba(255, 255, 255, 0.9);
          backdrop-filter: blur(6px);
        `}
      ></circle>
    )}
    <circle
      cx={r}
      cy={r}
      r={r}
      strokeWidth={strokeWidth * 2}
      css={css`
        transition: stroke-width 0.35s;
      `}
      clipPath={`url(#${id})`}
    ></circle>
  </React.Fragment>
);

const generateSimpleIcon = ({ name, path }) => ({
  w: 76,
  h: 76,
  Comp: ({ hover, filled }) => {
    let { strokeWidth } = hover ? hoverBorderStyle : baseStyle;
    const id = `${name}-${Math.round(Math.random() * 10000)}`;
    return (
      <g {...baseStyle}>
        <OutlineCircle id={id} strokeWidth={strokeWidth} filled={filled} />
        <path stroke="none" fill="currentColor" d={path}></path>
      </g>
    );
  },
});

const icons = {
  persons: {
    w: 28,
    h: 28,
    Comp: () => (
      <g {...baseStyle}>
        <path d="M17,23.8648533 C17,19.2110476 13.8659932,15.4383927 10,15.4383927 C6.13400675,15.4383927 3,19.2110476 3,23.8648533 C3,25.9629059 17,25.9629059 17,23.8648533 Z"></path>
        <path d="M18.4440047,19.2209864 C21.4044121,19.524629 26,19.2497646 26,18.396393 C26,15.6041096 23.7614237,13.3405167 21,13.3405167 C19.0883684,13.3405167 17.4273001,14.4252941 16.5859912,16.0192916"></path>
        <circle cx="10" cy="9.24264069" r="3.5"></circle>
        <circle cx="21" cy="8.34051671" r="2.5"></circle>
      </g>
    ),
  },
  clock: {
    w: 28,
    h: 28,
    Comp: () => (
      <g {...baseStyle}>
        <circle cx="14.5" cy="16.5" r="10"></circle>
        <path d="M11,7.10999992 L11,7.10999992 L11,5 C11,3.8954305 11.8954305,3 13,3 L16,3 C17.1045695,3 18,3.8954305 18,5 L18,7.13447195 L18,7.13447195"></path>
        <line x1="14.5" y1="16.5" x2="14.5" y2="11.5"></line>
        <line x1="14.5" y1="16.5" x2="21.5" y2="16.5"></line>
        <line x1="21.5867839" y1="9.41321615" x2="23.5" y2="7.5"></line>
        <line
          x1="5.41"
          y1="9.41321615"
          x2="7.32"
          y2="7.50000016"
          transform="translate(6.365000, 8.456608) scale(-1, 1) translate(-6.365000, -8.456608) "
        ></line>
      </g>
    ),
  },
  plus: {
    w: 40,
    h: 40,
    Comp: ({ hover, minus = false, r }) => {
      let { strokeWidth } = hover ? hoverBorderStyle : baseStyle;
      const id = `plus-${Math.round(Math.random() * 10000)}`;
      const coords = [(14 / 20) * r, (20 / 20) * r, (26 / 20) * r];

      return (
        <g {...baseStyle}>
          <OutlineCircle id={id} strokeWidth={strokeWidth} r={r} />
          <line
            x1={coords[0]}
            y1={coords[1]}
            x2={coords[2]}
            y2={coords[1]}
            css={css(
              `
              transition: transform 0.3s cubic-bezier(.5,1,.5,1);
              transform-origin: center;
              transform: rotate(0deg);`,
              minus ? `transform: rotate(180deg);` : ``
            )}
          ></line>
          <line
            x1={coords[1]}
            y1={coords[2]}
            x2={coords[1]}
            y2={coords[0]}
            css={css(
              `
            transition: transform 0.3s cubic-bezier(.5,1,.5,1), opacity .3s;
            transform-origin: center;
            transform: rotate(0deg);`,
              minus ? `transform: rotate(270deg); opacity: 0;` : ``
            )}
          ></line>
        </g>
      );
    },
  },
  arrowRight: {
    w: 76,
    h: 76,
    Comp: ({ hover }) => {
      let { strokeWidth } = hover ? hoverBorderStyle : baseStyle;
      const id = `arrowRight-${Math.round(Math.random() * 10000)}`;
      return (
        <g {...baseStyle}>
          <OutlineCircle id={id} strokeWidth={strokeWidth} />
          <line x1="23" y1="38" x2="50" y2="38"></line>
          <polyline
            transform="translate(41.000000, 38.000000) rotate(-315.000000) translate(-41.000000, -38.000000) "
            points="34 31 48 31 48 45"
          ></polyline>
        </g>
      );
    },
  },
  whatsapp: {
    w: 76,
    h: 76,
    Comp: ({ hover, filled }) => {
      let { strokeWidth } = hover ? hoverBorderStyle : baseStyle;
      const id = `whatsapp-${Math.round(Math.random() * 10000)}`;
      return (
        <g {...baseStyle}>
          <OutlineCircle id={id} strokeWidth={strokeWidth} filled={filled} />
          <g
            transform="translate(20.000000, 20.000000)"
            stroke="none"
            fill="currentColor"
          >
            <path d="M13.576,10.743 C13.244,10.036 12.895,10.022 12.579,10.009 C12.321,9.999 12.026,10 11.731,10 C11.435,10 10.955,10.106 10.550,10.531 C10.144,10.955 9,11.982 9,14.070 C9,16.159 10.587,18.177 10.808,18.460 C11.029,18.743 13.871,23.166 18.373,24.867 C22.114,26.281 22.875,26.000 23.688,25.929 C24.500,25.859 26.308,24.903 26.677,23.911 C27.046,22.920 27.046,22.071 26.935,21.894 C26.824,21.716 26.529,21.610 26.086,21.398 C25.643,21.186 23.466,20.159 23.060,20.017 C22.654,19.876 22.359,19.805 22.063,20.230 C21.768,20.655 20.920,21.610 20.661,21.894 C20.403,22.177 20.145,22.212 19.702,22.000 C19.259,21.787 17.832,21.340 16.140,19.894 C14.824,18.769 13.935,17.379 13.677,16.954 C13.418,16.530 13.649,16.300 13.871,16.088 C14.070,15.898 14.314,15.593 14.536,15.345 C14.756,15.097 14.830,14.920 14.978,14.637 C15.126,14.354 15.052,14.106 14.941,13.894 C14.830,13.681 13.970,11.582 13.576,10.743"></path>
            <path d="M18.075,32.678 L18.069,32.678 C15.395,32.676 12.773,31.962 10.486,30.611 L9.942,30.289 L4.303,31.761 L5.808,26.290 L5.454,25.729 C3.963,23.369 3.175,20.640 3.176,17.839 C3.179,9.664 9.862,3.013 18.080,3.013 C22.060,3.014 25.800,4.559 28.613,7.361 C31.426,10.163 32.974,13.889 32.973,17.851 C32.969,26.026 26.286,32.678 18.075,32.678 M30.754,5.231 C27.370,1.859 22.869,0.002 18.075,0 C8.194,0 0.154,8.002 0.150,17.838 C0.149,20.982 0.974,24.051 2.543,26.756 L0,36 L9.502,33.519 C12.120,34.940 15.067,35.689 18.067,35.691 L18.075,35.691 C27.953,35.691 35.995,27.687 36,17.852 C36.001,13.085 34.138,8.603 30.754,5.231"></path>
          </g>
        </g>
      );
    },
  },
  facebook: generateSimpleIcon({
    name: "facebook",
    path:
      "M40.408,55 L33.525,55 L33.525,37.998 L30.086,37.998 L30.086,32.139 L33.525,32.139 L33.525,28.621 C33.525,23.842 35.467,21 40.983,21 L45.576,21 L45.576,26.859 L42.705,26.859 C40.558,26.859 40.416,27.678 40.416,29.206 L40.408,32.139 L45.608,32.139 L45.000,37.998 L40.408,37.998 L40.408,55 Z",
  }),
  twitter: generateSimpleIcon({
    name: "twitter",
    path:
      "M31.837,52 C44.843,52 51.954,41.228 51.954,31.888 C51.954,31.582 51.948,31.277 51.934,30.974 C53.315,29.976 54.515,28.731 55.461,27.314 C54.194,27.877 52.831,28.256 51.401,28.427 C52.861,27.552 53.981,26.167 54.510,24.517 C53.144,25.327 51.631,25.915 50.020,26.233 C48.730,24.859 46.893,24 44.859,24 C40.955,24 37.788,27.165 37.788,31.067 C37.788,31.622 37.850,32.161 37.972,32.679 C32.095,32.383 26.885,29.571 23.398,25.293 C22.791,26.338 22.440,27.552 22.440,28.846 C22.440,31.299 23.689,33.464 25.587,34.730 C24.427,34.694 23.337,34.376 22.385,33.846 C22.384,33.876 22.384,33.905 22.384,33.936 C22.384,37.360 24.821,40.218 28.056,40.866 C27.462,41.028 26.837,41.114 26.192,41.114 C25.737,41.114 25.294,41.070 24.863,40.987 C25.763,43.795 28.373,45.839 31.468,45.896 C29.048,47.793 25.999,48.922 22.686,48.922 C22.116,48.922 21.553,48.890 21,48.824 C24.129,50.829 27.844,52 31.838,52",
  }),
  pinterest: generateSimpleIcon({
    name: "pinterest",
    path:
      "M37.999,21 C28.611,21 21,28.611 21,38 C21,45.202 25.480,51.358 31.804,53.835 C31.656,52.490 31.521,50.422 31.863,48.953 C32.172,47.627 33.857,40.503 33.857,40.503 C33.857,40.503 33.348,39.485 33.348,37.979 C33.348,35.615 34.718,33.851 36.424,33.851 C37.875,33.851 38.575,34.940 38.575,36.245 C38.575,37.704 37.646,39.885 37.167,41.906 C36.767,43.598 38.016,44.978 39.685,44.978 C42.706,44.978 45.029,41.792 45.029,37.193 C45.029,33.122 42.104,30.277 37.928,30.277 C33.091,30.277 30.251,33.905 30.251,37.654 C30.251,39.115 30.814,40.682 31.517,41.534 C31.656,41.702 31.676,41.849 31.634,42.021 C31.505,42.558 31.219,43.713 31.162,43.949 C31.088,44.260 30.916,44.326 30.594,44.176 C28.471,43.188 27.143,40.084 27.143,37.591 C27.143,32.228 31.039,27.303 38.376,27.303 C44.273,27.303 48.856,31.506 48.856,37.122 C48.856,42.980 45.162,47.696 40.034,47.696 C38.312,47.696 36.692,46.801 36.138,45.744 C36.138,45.744 35.286,48.989 35.079,49.785 C34.695,51.261 33.659,53.112 32.966,54.241 C34.557,54.734 36.247,55 37.999,55 C47.388,55 55,47.388 55,38 C55,28.611 47.388,21 37.999,21",
  }),
};

const Icon = ({ type, innerProps, width, height, ...props }) => {
  const { w, h, Comp } = icons[type];
  return (
    <svg
      width={`${width || w}px`}
      height={`${height || h}px`}
      viewBox={`0 0 ${width || w} ${height || h}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Comp {...innerProps} />
    </svg>
  );
};
export default Icon;
