import * as React from "react";
import { navigate } from "gatsby";
import { css, withTheme } from "@delight-js/react";
import { useMountedState } from "react-use";

import Link from "../components/Link";
import { Overline, H1 } from "../components/texts";
import Chip from "../components/Chip";
import NewsletterBlock from "../components/NewsletterBlock";
import SpaceBetween from "../components/layout/SpaceBetween";
import Grid from "../components/layout/Grid";
import Row from "../components/layout/Row";
import Cell from "../components/layout/Cell";
import Button from "../components/Button";
import PreviewRow from "../components/PreviewRow";
import Sticky from "../components/Sticky";
import ShareMenu from "../components/ShareMenu";
import SharingBar from "../components/SharingBar";

const setCategoriesWithUrlParam = (initialCategories, search = "") => {
  let urlCategories = [];
  const urlParams = new URLSearchParams(search);
  const filter = urlParams.get("filter");
  if (filter && filter.length) {
    urlCategories = filter.split(",");
  }
  if (!urlCategories.length) {
    return initialCategories;
  }
  return initialCategories.map((c) => ({
    ...c,
    isActive: urlCategories.includes(c.slug) || c.isActive,
  }));
};

const PaginatedTemplate = withTheme(({ theme, ...props }) => {
  const {
    pageData: { title, overline, locale },
    articles,
    translations,
    overviewUrl,
    baseUrl,
    pagination,
    categories: initialCategories,
  } = props.pageContext;
  const fullUrl =
    props?.location?.href || `${baseUrl}${props?.location?.pathname}`;
  const itemsContainerRef = React.useRef(null);
  const [articleItems, setArticleItems] = React.useState(articles);
  const [isSmallWindow, setIsSmallWindow] = React.useState(true);
  const [filterBarY, setFilterBarY] = React.useState();
  const [filterBarHeight, setFilterBarHeight] = React.useState();
  const [loadingOtherPages, setLoadingOtherPages] = React.useState(false);
  const [headerHeight, setHeaderHeight] = React.useState();
  const [offsetLeft, setOffsetLeft] = React.useState();
  const isMounted = useMountedState();
  const [categories, setCategories] = React.useState(() =>
    setCategoriesWithUrlParam(
      initialCategories,
      props?.location?.search?.substring(1)
    )
  );
  React.useEffect(() => {
    if (props.location.search) {
      setCategories(() =>
        setCategoriesWithUrlParam(
          initialCategories,
          props?.location?.search?.substring(1)
        )
      );
    }
  }, [initialCategories, props.location.search]);
  React.useEffect(() => {
    let dimensionWatcher = window.__WATCHERS__.size.subscribe({
      matchCondition: ([width]) => width >= 1024,
      onchange: ([width]) => {
        const filterBarBox =
          document.getElementById("filter-bar")?.getBoundingClientRect() || {};
        const curFilterBarY = filterBarBox.top + window.scrollY,
          curFilterBarHeight = filterBarBox.height,
          curHeaderHeight =
            width >= 1024
              ? document
                  .querySelector("#gatsby-focus-wrapper > header")
                  ?.getBoundingClientRect().height
              : 8,
          curOffsetLeft = document
            .querySelector("#gatsby-focus-wrapper > main")
            ?.getBoundingClientRect().left;
        setFilterBarY(curFilterBarY);
        setFilterBarHeight(curFilterBarHeight);
        setHeaderHeight(curHeaderHeight);
        setOffsetLeft(curOffsetLeft);
      },
      onappear: () => setIsSmallWindow(false),
      ondisappear: () => setIsSmallWindow(true),
    });
    return () => {
      if (dimensionWatcher) {
        window.__WATCHERS__.size.unsubscribe(dimensionWatcher);
      }
    };
  }, []);

  React.useEffect(() => {
    if (
      pagination.page === 0 ||
      !loadingOtherPages ||
      !itemsContainerRef.current ||
      !filterBarHeight ||
      !headerHeight
    ) {
      return;
    }
    const firstOfPage = itemsContainerRef.current.children.item(
      pagination.perPage * pagination.page
    );
    window.scrollTo(
      0,
      Math.max(
        0,
        firstOfPage.getBoundingClientRect().top +
          window.scrollY -
          filterBarHeight -
          headerHeight
      )
    );
  }, [
    pagination.perPage,
    pagination.page,
    loadingOtherPages,
    articleItems.length,
    filterBarHeight,
    headerHeight,
  ]);

  React.useEffect(() => {
    if (loadingOtherPages || pagination.count <= pagination.perPage) {
      return;
    }

    let newArticleItems = Array(pagination.count).fill({});
    newArticleItems.splice(
      pagination.page * pagination.perPage,
      articleItems.length,
      ...articleItems
    );
    setArticleItems(newArticleItems);
    setLoadingOtherPages(true);

    const path = `/page-data${pagination.basePath}`;
    const loadPage = (page) => {
      fetch(`${path}${page === 0 ? "" : `/${page + 1}`}/page-data.json`)
        .then((response) => response.json())
        .then((data) => {
          if (!isMounted()) {
            return;
          }
          const articles = data?.result?.pageContext?.articles || [];

          if (!articles.length) {
            return;
          }
          setArticleItems((prev) => {
            prev.splice(
              page * pagination.perPage,
              articles.length,
              ...articles
            );
            return [...prev];
          });
        });
    };

    for (
      let i = 0, len = Math.ceil(pagination.count / pagination.perPage);
      i < len;
      i++
    ) {
      if (i !== pagination.page) {
        loadPage(i);
      }
    }
  }, [
    articleItems,
    isMounted,
    loadingOtherPages,
    pagination.basePath,
    pagination.page,
    pagination.count,
    pagination.perPage,
  ]);

  const route = (newCategories) => {
    // route when categories change
    const navOptions = {
      state: {
        scrollPosition: Math.min(filterBarY - headerHeight, window.scrollY),
      },
    };
    if (newCategories.url === overviewUrl) {
      navigate(overviewUrl, navOptions);
    }
    let activePrimary = newCategories.find((c) => c.isActive && c.isPrimary);
    let activeSecondaries = newCategories.filter(
      (c) => c.isActive && !c.isPrimary
    );
    if (!activePrimary && !activeSecondaries.length) {
      // no category is selected, so go to the overview page
      navigate(overviewUrl, navOptions);
    } else if (activePrimary) {
      // a primary category is selected, so go with it for the main url and add others as param value list
      navigate(
        `${activePrimary.url}${
          activeSecondaries.length
            ? `?filter=${activeSecondaries.map((c) => c.slug).join(",")}`
            : ""
        }`,
        navOptions
      );
    } else {
      // no primary category is selected, so go with the first secondary category as primary url and add left-overs as param value list
      const newCategory = activeSecondaries.shift();
      navigate(
        `${newCategory.url}${
          activeSecondaries.length
            ? `?filter=${activeSecondaries.map((c) => c.slug).join(",")}`
            : ""
        }`,
        navOptions
      );
    }
  };

  const handleFilterChange = (index = -1) => (e) => {
    e.preventDefault();
    const { isActive, isPrimary } = categories[index] || {};
    const newCategories = categories.map((c, jndex) => ({
      ...c,
      isActive:
        jndex === index
          ? !isActive
          : (c.isPrimary && isPrimary) || index === -1
          ? false
          : c.isActive,
    }));
    route(newCategories);
    setCategories(newCategories);
  };

  const stickyCondition = (y) => {
    if (!filterBarY || typeof headerHeight === "undefined") {
      return false;
    }
    return y >= filterBarY - headerHeight;
  };

  const activeCategories = categories
    .filter((c) => c.isActive)
    .map((c) => c.id);
  const activeCategoriesLength = activeCategories.length;

  const filteredArticleItems = articleItems.filter((a) => {
    if (typeof a.categories === "undefined") {
      return true;
    }
    for (let i = 0; i < activeCategoriesLength; i++) {
      if (!a.categories.includes(activeCategories[i])) {
        return false;
      }
    }
    return true;
  });

  return (
    <React.Fragment>
      <Grid
        css={css`
        $py14;
      display: flex;
      box-sizing: border-box;
      flex-direction: column;
    `(theme)}
      >
        <Row
          css={css`
            $pb4;
            min-height: {160, 160,,,,384}({"step": 8, "method": "round"})px;
          `(theme)}
        >
          <Cell
            span={{ default: 10 / 12 }}
            offset={{ default: 1 / 12 }}
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
            `}
          >
            <Overline css={css`$mb1;`(theme)}>{overline}</Overline>
            <H1
              as="div"
              css={css`
                max-width: 1050px;
              `}
            >
              <h1
                css={css`
                  display: inline;
                  font: inherit;
                `}
              >
                {/* <AlignEvenly text={headline} /> */}
                {title}
              </h1>
              {!!fullUrl && (
                <div
                  css={css`
                    display: inline-block;
                    position: relative;
                    left: 0;
                    top: 0;
                    width: 0;
                  `}
                >
                  {"\u00a0"}
                  <ShareMenu
                    url={fullUrl}
                    css={css`
                      position: absolute;
                      left: 5px;
                      display: block;
                      top: calc(50% - 24px);
                      @media:md {
                        top: calc(50% - 32px);
                      }
                    `}
                  />
                </div>
              )}
            </H1>
            {!!fullUrl && (
              <ShareMenu
                url={fullUrl}
                css={css`
                  display: none;

                  @media:xl {
                    display: block;
                    position: fixed;
                    left: 5px;
                    bottom: 5px;
                    z-index: 999999;
                  `(theme)}
              />
            )}
          </Cell>
        </Row>
        <Sticky
          id="filter-bar"
          condition={stickyCondition}
          position={[offsetLeft, headerHeight]}
        >
          {({ isSticky }) => (
            <div
              css={css`
                $pb1;
                position: relative;
                padding-top: 1px;
                &:after {
                  content: "";
                  display: block;
                  position: absolute;
                  left: 0;
                  top: -8px;
                  width: 100%;
                  height: calc(100% + 8px);
                  z-index: -1;
                  backdrop-filter: blur(6px);
                  background: rgba(255, 255, 255, 0.89);
                }

                @media:xl {
                  &:after {
                    top: 0;
                    height: 100%;
                  }
                }
              `(theme)}
            >
              <Row
                css={css`
                  @media:md {
                    padding-top: 8px;
                  }
                  @media:xl {
                    padding-top: 0;
                  }
                `(theme)}
              >
                <Cell
                  span={{
                    default: isSticky && isSmallWindow ? 11 / 12 : 10 / 12,
                    xl: 10 / 12,
                  }}
                  offset={{ default: 1 / 12 }}
                >
                  <SpaceBetween
                    css={
                      isSticky && isSmallWindow
                        ? css`
                            padding-right: 70px;

                            @media:md {
                              padding-right: 80px;
                            }

                            @media:xl {
                              padding-right: 0;
                            }
                          `(theme)
                        : null
                    }
                  >
                    {categories.map((item, index) => (
                      <Chip
                        key={`${
                          item.name
                        }-${(!!item.isActive).toString()}-${Math.floor(
                          Math.random() * 10000
                        )}`}
                        as={Link}
                        to={item.url}
                        secondary={!item.isPrimary}
                        filled={item.isActive}
                        onClick={handleFilterChange(index)}
                        css={css`
                          position: relative;
                        `}
                      >
                        {item.name}
                      </Chip>
                    ))}
                    {categories.some((item) => item.isActive) && (
                      <Chip
                        muted
                        as={Link}
                        to={overviewUrl}
                        onClick={handleFilterChange()}
                        css={css`
                          transition: opacity 0.35s;
                        `}
                      >
                        x Reset
                      </Chip>
                    )}
                  </SpaceBetween>
                </Cell>
              </Row>
            </div>
          )}
        </Sticky>
      </Grid>
      <Grid
        ref={itemsContainerRef}
        css={
          (pagination.previousLink || pagination.nextLink) && !loadingOtherPages
            ? {}
            : css`$mb26;`(theme)
        }
      >
        {!!filteredArticleItems.length ? (
          filteredArticleItems.map(({ overline, title, image, to }, i) => (
            <PreviewRow
              key={to || title || i}
              to={to}
              overline={overline}
              title={title}
              image={image}
              css={i > 0 ? css`$mt14;`(theme) : {}}
            />
          ))
        ) : (
          <Row>
            <Cell
              span={{ default: 10 / 12, md: 8 / 12, xl: 10 / 12 }}
              offset={{ default: 1 / 12, md: 2 / 12, xl: 1 / 12 }}
            >
              {translations.noPostsFound}
            </Cell>
          </Row>
        )}
      </Grid>

      {!!(pagination.nextLink || pagination.previousLink) &&
        !loadingOtherPages && (
          <Grid css={css`$mb26; $mt14;`(theme)}>
            <Row>
              <Cell offset={{ default: 1 / 12 }} span={{ md: 5 / 12 }}>
                {!!pagination.previousLink && (
                  <Button as={Link} to={pagination.previousLink}>
                    {translations.previousArticles}
                  </Button>
                )}
              </Cell>
              {!!pagination.nextLink && (
                <Cell
                  offset={{ default: 1 / 12, md: 0 }}
                  span={{ md: 5 / 12 }}
                  css={css`
                    @media:md {
                      text-align: right;
                    }
                  `(theme)}
                >
                  <Button as={Link} to={pagination.nextLink}>
                    {translations.nextArticles}
                  </Button>
                </Cell>
              )}
            </Row>
          </Grid>
        )}
      <SharingBar url={fullUrl} css={css`$mb26;`(theme)} />
      <NewsletterBlock css={css`$mb26;`(theme)} locale={locale} />
    </React.Fragment>
  );
});

export default PaginatedTemplate;
