import isPropValid from "@emotion/is-prop-valid";
import { css, withTheme } from "@delight-js/react";
import styled from "@delight-js/styled";

const Chip = withTheme(styled("span", {
  shouldForwardProp: (prop) => isPropValid(prop) || prop === "state",
})`
  ${(props) =>
    css`
      $layerBorder;
      $textCaption;
      border-color: currentColor;
      display: inline-block;
      line-height: 22px;
      color: $color-spot1;
      padding: 0 $space-3;
      text-decoration: inherit;
      transition: box-shadow .2s;

      &:hover {
        box-shadow: inset 0px 0 0px 2px currentColor;
      }
    `(props.theme)}
  ${({ muted, filled, secondary, theme }) =>
    css(
      filled
        ? `
        color: white;
        background-color: ${secondary ? "$color-spot2" : "$color-spot1"};
        border-color: ${secondary ? "$color-spot2" : "$color-spot1"};

      &:hover {
        box-shadow: inset 0px 0 0px 2px currentColor;
      }
    `
        : secondary
        ? `
          color: $color-spot2;
          filter: brightness(0.94);`
        : muted
        ? `color: $color-neutral;`
        : ""
    )(theme)};
`);

export default Chip;
