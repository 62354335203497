import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { css, withTheme } from "@delight-js/react";
import Link from "./Link";
import IconButton from "./IconButton";
import { useSprings, useSpring, animated } from "@react-spring/web";

const to = ({ show, section = 1, offset = 0, distance, translate }) => (
  item,
  index,
  { length }
) => ({
  x: show
    ? Math.round(
        distance *
          Math.cos(
            ((Math.PI * 2 * section) / length) * index -
              Math.PI * (0.5 + offset)
          ) +
          translate
      )
    : translate,
  y: show
    ? Math.round(
        distance *
          Math.sin(
            ((Math.PI * 2 * section) / length) * index -
              Math.PI * (0.5 + offset)
          ) +
          translate
      )
    : translate,
  scale: show ? 1 : 0,
  opacity: show ? 1 : 0,
  delay: index * 40,
});

const ShareMenu = withTheme(({ theme, url, isFixed = false, ...props }) => {
  const iconSize = 48;
  const buttonRef = React.useRef();
  const [show, setShow] = React.useState(false);
  const [offset, setOffset] = React.useState(0);
  const [section, setSection] = React.useState(1);
  const [distance, setDistance] = React.useState(
    0.67 * iconSize + iconSize / 2
  );

  const {
    site: {
      siteMetadata: { sharing },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            sharing {
              name
              link
            }
          }
        }
      }
    `
  );

  React.useEffect(() => {
    const handleDocumentClick = () => {
      if (show) {
        setShow(false);
      }
    };
    document.addEventListener("click", handleDocumentClick);
    document.addEventListener("scroll", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
      document.removeEventListener("scroll", handleDocumentClick);
    };
  });

  React.useEffect(() => {
    let watcher = window.__WATCHERS__.size.subscribe({
      onchange([width, height]) {
        const newDistance = 0.67 * iconSize + iconSize / 2;
        const totalDistance = newDistance + iconSize / 2;

        if (buttonRef.current) {
          const boundingBox = buttonRef.current.getBoundingClientRect();
          const headroom = [
            // top:
            boundingBox.top + (isFixed ? 0 : window.scrollY) > totalDistance,
            // right:
            width - (boundingBox.right + (isFixed ? 0 : window.scrollX)) >
              totalDistance,
            // bottom:
            height - (boundingBox.bottom + (isFixed ? 0 : window.scrollY)) >
              totalDistance,
            // left:
            boundingBox.left + (isFixed ? 0 : window.scrollX) > totalDistance,
          ];
          const newSection =
            headroom.filter((value) => value).length >= 3 ? 1 : 0.5;
          setSection(newSection);
          const newOffset = ((headroom.lastIndexOf(false) + 5) % 4) / 2;
          setOffset(newOffset);
          setDistance(newDistance / newSection);
        }
      },
    });
    return () => {
      if (watcher) {
        window.__WATCHERS__.size.unsubscribe(watcher);
      }
    };
  });

  const veilAnimation = useSpring({
    scale: show ? 1 : 0,
    opacity: show ? 1 : 0,
  });

  const springs = useSprings(
    sharing.length,
    Array.from({ length: sharing.length }).map(
      to({
        show,
        offset,
        section,
        distance,
        translate: (64 - iconSize) / 2 - 32,
      })
    )
  );

  const toggle = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShow((prev) => !prev);
  };
  return (
    <span
      css={css`
        position: relative;
        z-index: 999;
      `}
      {...props}
    >
      <animated.div
        css={css`
          position: absolute;
          left: calc(50% - 200px);
          top: calc(50% - 200px);
          width: 400px;
          height: 400px;
          background: radial-gradient(
            circle,
            rgba(255, 255, 255, 0.8) 0%,
            rgba(255, 255, 255, 0.6) 44%,
            rgba(255, 255, 255, 0) 68%
          );
        `(theme)}
        style={veilAnimation}
      ></animated.div>
      <button
        css={css`
          position: relative;
          display: block;
          z-index: 10;
          width: 48px;
          height: 48px;
          margin: 0;
          padding: 0;
          border: none;
          cursor: pointer;
          backface-visibility: hidden;
          background: transparent;

          @media:md {
            width: 64px;
            height: 64px;
          }
        `(theme)}
        onClick={toggle}
        ref={buttonRef}
      >
        <svg
          width="64px"
          height="64px"
          viewBox="0 0 64 64"
          css={css`
            display: block;
            width: 100%;
            height: 100%;
          `(theme)}
        >
          <g
            css={css`
              fill: none;
              stroke-linecap: square;
              stroke-width: 2;
              stroke: $color-spot1;
            `(theme)}
          >
            <circle cx="25" cy="32" r="3"></circle>
            <circle cx="39" cy="25" r="3"></circle>
            <circle cx="39" cy="39" r="3"></circle>
            <line
              x1="27.6903011"
              y1="33.3451506"
              x2="36.340266"
              y2="37.670133"
            ></line>
            <line
              x1="36.307858"
              y1="26.346071"
              x2="27.6693825"
              y2="30.6653087"
            ></line>
          </g>
        </svg>
      </button>
      {sharing
        .filter((s) => s.link)
        .map((s, index) => (
          <animated.div
            key={s.name}
            style={springs[index]}
            css={css`
              position: absolute;
              left: 50%;
              top: 50%;
            `}
          >
            <Link
              href={`${s.link}${escape(
                url +
                  `${url.indexOf("?") > -1 ? "&" : "?"}utm_source=${
                    s.name
                  }_WebsiteShare`
              )}`}
              title={`Share on ${s.name}`}
              css={css`
                display: block;
                color: $color-spot1;
              `(theme)}
            >
              <IconButton
                type={s.name.toLowerCase()}
                filled
                css={css`
                  display: block;
                  width: 48px;
                  height: 48px;
                `(theme)}
              />
            </Link>
          </animated.div>
        ))}
    </span>
  );
});

export default ShareMenu;
