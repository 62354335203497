import * as React from "react";

const Sticky = ({
  children,
  id,
  condition,
  position: [left, top],
  preserveHeight = true,
  ...props
}) => {
  const containerRef = React.useRef();
  const wrapperRef = React.useRef();
  const [isSticky, setIsSticky] = React.useState(false);
  const [containerHeight, setContainerHeight] = React.useState();
  const [wrapperWidth, setWrapperWidth] = React.useState();

  const containerStyle = { position: "relative" },
    wrapperStyle = {
      left,
      top,
      zIndex: 999,
    };
  if (preserveHeight && containerHeight) {
    containerStyle.height = `${containerHeight}px`;
  }
  if (isSticky) {
    wrapperStyle.position = "fixed";
    wrapperStyle.width = `${wrapperWidth}px`;
  }

  React.useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    let scrollWatcher;
    if (condition) {
      scrollWatcher = window.__WATCHERS__.scroll.subscribe({
        matchCondition: condition,
        onappear: () => setIsSticky(true),
        ondisappear: () => setIsSticky(false),
      });
    }
    let dimensionWatcher = window.__WATCHERS__.size.subscribe({
      onchange() {
        if (wrapperRef.current) {
          setContainerHeight(wrapperRef.current.offsetHeight);
        }
        if (containerRef.current) {
          setWrapperWidth(containerRef.current.offsetWidth);
        }
      },
    });
    return () => {
      if (scrollWatcher) {
        window.__WATCHERS__.scroll.unsubscribe(scrollWatcher);
      }
      if (dimensionWatcher) {
        window.__WATCHERS__.size.unsubscribe(dimensionWatcher);
      }
    };
  }, [condition]);
  return (
    <div ref={containerRef} id={id} style={containerStyle}>
      <div ref={wrapperRef} {...props} style={wrapperStyle}>
        {typeof children === "function" ? children({ isSticky }) : children}
      </div>
    </div>
  );
};

export default Sticky;
