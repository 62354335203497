import * as React from "react";
import Icon from "./Icon";

const IconButton = ({ type, className, ...props }) => {
  const [hover, setHover] = React.useState(false);
  const toggleHover = (e) => setHover(e.type === "mouseover");
  return (
    <Icon
      type={type}
      className={className}
      innerProps={{ ...props, hover }}
      onMouseOver={toggleHover}
      onMouseOut={toggleHover}
    />
  );
};

export default IconButton;
